import * as bootstrap from 'bootstrap'

const registerButton = document.getElementById('registerButton')
const registerForm = document.getElementById('registerForm')

if (registerButton) {
  registerButton.addEventListener('click', (event) => {
    registerButton.disabled = true
    registerForm.classList.add('was-validated')

    if (registerForm.checkValidity()) {
      const data = {}
      data.mailType = 'confirm_wedding'
      data.name = document.getElementById('name').value
      data.mail = document.getElementById('mail').value
      data.persons = document.getElementById('persons').value
      data.vegan = document.getElementById('vegan').value
      data.vegetarian = document.getElementById('vegetarian').value
      data.lactosefree = document.getElementById('lactosefree').value
      data.glutenfree = document.getElementById('glutenfree').value
      data.otherComments = document.getElementById('otherComments').value
      data.car = document.forms.registerForm.elements['rideOption'].value
      data.canTransport = document.getElementById('canTransports').value

      fetch(`${process.env.API_URL}/sendMail`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((responseData) => {
          if (responseData.status === 'success') {
            document.getElementById('registration').classList.add('d-none')
            document.getElementById('confirmed').classList.remove('d-none')
          }
        })
        .catch((error) => {
          document.getElementById('registration').classList.add('d-none')
          document.getElementById('error').classList.remove('d-none')
        })
    } else {
      registerButton.disabled = false
    }
  })
}
